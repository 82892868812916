import React, { FunctionComponent } from "react";
import { VideoPlayV1 } from "../../images/icons/videoPlayV1";

interface PlayInterface {
  color?: string;
  onKeyDown?: (e: any) => void;
}

const Play: FunctionComponent<PlayInterface> = ({ color, onKeyDown }) => {
  return (
    <i className="play keyboard-focus" tabIndex={0} onKeyDown={onKeyDown}>
      <span className="u-sr-only">Play Video</span>
      <VideoPlayV1 />
    </i>
  );
};

export default Play;
